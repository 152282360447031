export const environment = {
  name: "TEST",
  apiUrl: "https://fragile-be.map-hosting.it/api/v1/",
  version: '0.1.22',
  viewerUrl:'https://viewer2-test.maphosting.it/viewer/index.php/fragile/',
  auth0: {
    clientId: "vEXQdqyPTr8fDh5X00YMeHTn2RZp2NPQ",
    domain: "fragile.eu.auth0.com",
    audience: "https://fragile-be.map-hosting.it/",
    redirectUri: window.location.origin,
  },
  firebase: {
    apiKey: "AIzaSyBXrHfI2s4kzrxzv63X3J4g5okRs1JRrxA",
    authDomain: "fragile-437209.firebaseapp.com",
    projectId: "fragile-437209",
    storageBucket: "fragile-437209.appspot.com",
    messagingSenderId: "395921422982",
    appId: "1:395921422982:web:1af3ff7c39467893a0af07",
    collectionMisurazioni:"misurazioni",
    collectionProgetti:"progetti"
  },
  storageBucket: "fragile-437209.appspot.com",
};
